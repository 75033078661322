<template>
  <div class="min-w-full bg-white mt-2">
    <div class="flex flex-row overflow-x-hidden">
      <div class="flex whitespace-no-wrap">{{ camdata.location_from }}</div>
      <div class="flex px-2">=></div>
      <div class="flex-1 text-right whitespace-no-wrap">
        {{ camdata.location_to }}
      </div>
    </div>
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <img
        :key="camdata.image_base64"
        :src="camdata.image_base64"
        :alt="camdata.player_id"
        class="w-full max-h-36 rounded-sm shadow-md object-cover"
      />
    </transition>
    <div class="flex flex-row items-center">
      <div class="flex-1">{{ camdata.player_id }}</div>
    </div>
  </div>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to,
.slide-fade-leave-active {
  transform: translateX(-5px);
  opacity: 0;
}
</style>

<script>
export default {
  name: "Camera",
  components: {},
  props: ["camdata"],
  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    loadViewDevice: function (camdata) {
      this.$emit("loadviewdevice", camdata);
    },
  },
};
</script>